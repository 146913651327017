import React, { useState, useEffect } from 'react';
import { Table, Button, Select, Card, Typography, Space, Row, Col, Tag } from 'antd';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import Timer from "../assets/SideBarImages/Timer.png";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const { Option } = Select;

const Reports = () => {
  const currentYear = moment().year();
  const years = Array.from({ length: 6 }, (_, i) => currentYear - 5 + i);
  const months = moment.months();

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [transactions, setTransactions] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [summary, setSummary] = useState({
    totalSales: 0,
    totalDiscount: 0,
    netTotal: 0,
    cashSales: 0,
    cardSales: 0,
    totalTransactions: 0
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchMonthlyTransactions();
  }, [selectedYear, selectedMonth]);

  const fetchMonthlyTransactions = async () => {
    try {
      const date = moment(`${selectedYear}-${selectedMonth + 1}`, 'YYYY-M');
      const response = await axios.get(`https://bkpos.debuxglobal.com/api/reports/monthly`, {
        params: {
          month: date.format('YYYY-MM'),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      
      setTransactions(response.data.transactions);
      setSummary({
        totalSales: response.data.summary.totalSales,
        totalDiscount: response.data.summary.totalDiscount,
        netTotal: response.data.summary.netTotal,
        cashSales: response.data.summary.cashSales,
        cardSales: response.data.summary.cardSales,
        totalTransactions: response.data.summary.totalTransactions
      });
    } catch (error) {
      console.error("Error fetching monthly report:", error);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const monthYear = moment(`${selectedYear}-${selectedMonth + 1}`, 'YYYY-M').format('MMMM YYYY');
  
    doc.setFontSize(20);
    doc.text(`Monthly Report - ${monthYear}`, 105, 15, { align: 'center' });
  
    doc.setFontSize(12);
    doc.text('Summary', 14, 30);
  
    const summaryData = [
      ['Total Sales', `LKR ${summary.totalSales.toFixed(2)}`],
      ['Total Discounts', `LKR ${summary.totalDiscount.toFixed(2)}`],
      ['Net Total', `LKR ${summary.netTotal.toFixed(2)}`],
      ['Cash Sales', `LKR ${summary.cashSales.toFixed(2)}`],
      ['Card Sales', `LKR ${summary.cardSales.toFixed(2)}`],
      ['Total Transactions', summary.totalTransactions.toString()]
    ];
  
    doc.autoTable({
      startY: 35,
      head: [['Metric', 'Value']],
      body: summaryData,
      theme: 'grid',
      headStyles: { fillColor: [24, 144, 255] },
      margin: { left: 14 },
      tableWidth: 100
    });
  
    doc.setFontSize(12);
    doc.text('Transactions', 14, doc.lastAutoTable.finalY + 15);
  
    const transactionData = transactions.map(t => [
      t.order_id,
      t.payment_method.toUpperCase(),
      `LKR ${t.total_amount.toFixed(2)}`,
      `LKR ${t.discount.toFixed(2)}`,
      `LKR ${t.net_total.toFixed(2)}`,
      moment(t.created_at).format('YYYY/MM/DD'),
      moment(t.created_at).format('HH:mm:ss')
    ]);
  
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 20,
      head: [['Order ID', 'Payment Method', 'Total Amount', 'Discount', 'Net Amount', 'Date', 'Time']],
      body: transactionData,
      theme: 'grid',
      headStyles: { fillColor: [24, 144, 255], halign: 'center' }, // Center-align table headings
      margin: { left: 14 },
      tableWidth: 180
    });
  
    doc.save(`Monthly Sales Report ${months[selectedMonth]} ${selectedYear}.pdf`);
  };
  
  const handleExportExcel = () => {
    const monthYear = moment(`${selectedYear}-${selectedMonth + 1}`, 'YYYY-M').format('MMMM YYYY');
  
    const summaryData = [
      ['Monthly Report Summary'],
      ['Year', selectedYear],
      ['Month', months[selectedMonth]],
      [],
      ['Total Sales Amount:', `LKR ${summary.totalSales.toFixed(2)}`],
      ['Total Discount Amount:', `LKR ${summary.totalDiscount.toFixed(2)}`],
      ['Net Sales Amount:', `LKR ${summary.netTotal.toFixed(2)}`],
      ['Cash Payments Amount:', `LKR ${summary.cashSales.toFixed(2)}`],
      ['Card Payments Amount:', `LKR ${summary.cardSales.toFixed(2)}`],
      ['Total Transactions', summary.totalTransactions]
    ];
  
    const transactionData = [
      ['Order ID', 'Payment Method', 'Total Amount', 'Discount', 'Net Amount', 'Date', 'Time'],
      ...transactions.map(t => [
        t.order_id,
        t.payment_method.toUpperCase(),
        t.total_amount,
        t.discount,
        t.net_total,
        moment(t.created_at).format('YYYY/MM/DD'),
        moment(t.created_at).format('HH:mm:ss')
      ])
    ];
  
    const wb = XLSX.utils.book_new();
  
    // Create the Summary sheet
    const ws1 = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, ws1, 'Summary');
  
    // Create the Transactions sheet
    const ws2 = XLSX.utils.aoa_to_sheet(transactionData);
    XLSX.utils.book_append_sheet(wb, ws2, 'Transactions');
  
    // Adjust column widths and align B column to right in Summary
    const wsSummary = wb.Sheets['Summary'];
    const rangeSummary = XLSX.utils.decode_range(wsSummary['!ref']);
  
    for (let i = rangeSummary.s.c; i <= rangeSummary.e.c; i++) {
      const width = { wch: 15 };
      wsSummary['!cols'] = wsSummary['!cols'] || [];
      wsSummary['!cols'][i] = width;
  
      if (i === 1) {  // Align second column (B) to the right
        for (let row = rangeSummary.s.r; row <= rangeSummary.e.r; row++) {
          const cellRef = XLSX.utils.encode_cell({ r: row, c: i });
          const cell = wsSummary[cellRef];
          if (cell) {
            cell.s = { ...cell.s, alignment: { horizontal: 'right' } };  // Preserve any existing styles
          }
        }
      }
    }
  
    // Adjust columns in Transactions
    const wsTransactions = wb.Sheets['Transactions'];
    const rangeTransactions = XLSX.utils.decode_range(wsTransactions['!ref']);
    const colsTransactions = [];
  
    for (let i = rangeTransactions.s.c; i <= rangeTransactions.e.c; i++) {
      colsTransactions.push({ wch: 15 });
    }
    wsTransactions['!cols'] = colsTransactions;
  
    XLSX.writeFile(wb, `Monthly Sales Report ${months[selectedMonth]} ${selectedYear}.xlsx`);
  };
  
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (method) => (
        <Tag color={method === "cash" ? "success" : "warning"}>
          {method.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount) => `LKR ${amount.toFixed(2)}`,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (discount) => `LKR ${discount.toFixed(2)}`,
    },
    {
      title: "Net Total",
      dataIndex: "net_total",
      key: "net_total",
      render: (netTotal) => `LKR ${netTotal.toFixed(2)}`,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (date) => moment(date).format("YYYY/MM/DD"),
    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "time",
      render: (date) => moment(date).format("HH:mm:ss"),
    },
  ];

  const StatisticCard = ({ title, value }) => {
    // Extract numeric value if it's in a string with "LKR" or other text
    const numericValue = typeof value === 'string'
      ? parseFloat(value.replace(/[^0-9.-]+/g, '')) // Remove non-numeric characters
      : value;
  
    // Format the value with commas if it's a valid number
    const formattedValue = !isNaN(numericValue) 
      ? numericValue.toLocaleString() 
      : value; // If it's not a number, show the original value
  
    return (
      <Card>
        <Text style={{ fontSize: "16px", fontFamily: 'Nunito', fontWeight: '500' }}>{title}</Text>
        <Title style={{ fontSize: "22px", fontFamily: 'Nunito', fontWeight: '700' }}>
          {`LKR ${formattedValue}`} {/* Always show "LKR" with formatted value */}
        </Title>
      </Card>
    );
  };
  

  const handleButtonClick = () => {
    navigate("/order-management");
  };

  const handleOnButtonClick = () => {
    navigate("/ongoing-orders");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "-15px",
        }}
      >
        <Text
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Nunito",
          }}
        >
          Reports
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
              border: isHovered ? "1px solid #F2890D" : "1px solid transparent",
              color: isHovered ? "#F2890D" : "inherit",
              padding: "10px",
              cursor: "pointer",
              transition: "border-color 0.3s ease", // Adding smooth transition
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleButtonClick}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            New Order
          </Button>
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
            }}
            onClick={handleOnButtonClick}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            On Going Orders
          </Button>
        </div>
      </div>
      {/* Row for Dropdowns on the left and Buttons on the right */}
      <Row
        gutter={16}
        style={{
          marginBottom: "15px", // Add margin-bottom for spacing below this row
          alignItems: "center",
        }}
      >
        {/* Dropdowns on the left */}
        <Col xs={24} sm={12} md={8}>
          <Space size="large">
            <Select
              value={selectedYear}
              onChange={setSelectedYear}
              style={{ width:'200px', height: '60px',fontSize: "16px", padding: "10px", fontFamily:'Nunito', fontWeight:'700' }} // Increased width and size
            >
              {years.map((year) => (
                <Option key={year} value={year}
                  style={{ fontSize: "16px", fontFamily:'Nunito', fontWeight:'700' }} // Increased font size
                >
                  {year}
                </Option>
              ))}
            </Select>
            <Select
              value={selectedMonth}
              onChange={setSelectedMonth}
              style={{ width:'200px', height: '60px',fontSize: "16px", padding: "10px", fontFamily:'Nunito', fontWeight:'700' }} // Increased width and size
            >
              {months.map((month, index) => (
                <Option key={index} value={index}
                  style={{ fontSize: "16px", fontFamily:'Nunito', fontWeight:'700' }} // Increased font size
                >
                  {month}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
  
        {/* Buttons on the right */}
        <Col xs={24} sm={12} md={16} style={{ textAlign: "right" }}>
          <Space size="large">
            <Button
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={handleExportExcel}
              style={{
                background: "#52c41a",
                fontSize: "16px", // Increase font size
                width: "180px",   // Adjusted button width
                height: "50px", 
                marginRight: "-14px", // Add right margin for spacing between buttons
              }}
            >
              Export Excel
            </Button>
            <Button
              type="primary"
              icon={<FilePdfOutlined />}
              onClick={handleExportPDF}
              danger
              style={{
                fontSize: "16px", // Increase font size
                width: "178px",   // Adjusted button width
                height: "50px",
                marginRight: "11px"   // Adjusted button height
              }}
            >
              Export PDF
            </Button>
          </Space>
        </Col>
      </Row>
  
      <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
        <Col span={8}>
          <StatisticCard
            title="Total Sales"
            value={`LKR ${summary.totalSales.toFixed(2)}`}
          />
        </Col>
        <Col span={8}>
          <StatisticCard
            title="Total Discounts"
            value={`LKR ${summary.totalDiscount.toFixed(2)}`}
          />
        </Col>
        <Col span={8}>
          <StatisticCard
            title="Net Total"
            value={`LKR ${summary.netTotal.toFixed(2)}`}
          />
        </Col>
        <Col span={8}>
          <StatisticCard
            title="Cash Sales"
            value={`LKR ${summary.cashSales.toFixed(2)}`}
          />
        </Col>
        <Col span={8}>
          <StatisticCard
            title="Card Sales"
            value={`LKR ${summary.cardSales.toFixed(2)}`}
          />
        </Col>
        <Col span={8}>
          <StatisticCard title="Total Transactions" value={summary.totalTransactions} />
        </Col>
      </Row>
  
      <Table
        columns={columns}
        dataSource={transactions}
        rowKey="order_id"
        bordered
        scroll={{ x: true }}
      />
    </div>
  );
  
}
  
export default Reports;